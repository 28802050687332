<app-layout>
  <ng-template #headerRightTemplate>
    <span
      class="header__menu-item clickable"
      (click)="onFeedback()"
    >
      Feedback
    </span>
    <app-label-dropdown
      class="help-dropdown"
      [label]="'Help'"
      [menuItems]="helpMenuItems"
    ></app-label-dropdown>
    <app-profile-dropdown
      *ngIf="user$ | async as user"
      [name]="user | username"
      [imgSrc]="userAvatar$"
    ></app-profile-dropdown>
  </ng-template>

  <ng-template #siderTemplate>
    <div class="layout-sider__wrapper">
      <div class="workspace-select">
        <app-workspace-select></app-workspace-select>
      </div>
      <ul
        nz-menu
        nzMode="inline"
        class="sider-menu"
      >
        <ng-container *ngFor="let item of menuItems">
          <li
            *appPermissions="item.permission"
            nz-menu-item
            class="sider-menu-item"
            routerLinkActive="active-link"
            #rla1="routerLinkActive"
            [routerLink]="item?.disabled === true ? null : '/' + workspace?.slug + item.route"
            [nzSelected]="isActive(item.route, item.partial)"
            [nzDisabled]="item?.disabled"
          >
            <i
              nz-icon
              [nzType]="item.icon"
            ></i>
            {{ item.title }}
          </li>
        </ng-container>
      </ul>
      <!--      <div class="layout-sider__promo-content">-->
      <!--        <div class="invite-promo">-->
      <!--          <img-->
      <!--            src="assets/images/invite-image.png"-->
      <!--            alt="invite-img"-->
      <!--          />-->
      <!--          <div class="invite-promo__heading">Earn 500 bonuses</div>-->
      <!--          <div class="invite-promo__content">Send an invite to a friend</div>-->

      <!--          <app-button-->
      <!--            [type]="'ghost'"-->
      <!--            [styles]="{-->
      <!--              border: 'none',-->
      <!--              'box-shadow': '0px 16px 48px -16px rgba(0, 0, 0, 0.24)'-->
      <!--            }"-->
      <!--          >-->
      <!--            Send an invite-->
      <!--          </app-button>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </ng-template>

  <ng-template #contentTemplate>
    <router-outlet></router-outlet>
  </ng-template>
</app-layout>
