import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { SubscriptionsService } from '@app/core/services/api/subscriptions.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirstRunSetupGuard  {
  constructor(private subscriptionsService: SubscriptionsService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    if (route.queryParams.redirect_status === 'succeeded') return true;
    // exclude stripe redirect
    return this.subscriptionsService.getSubscriptions().pipe(
      map((data) => {
        if (data.total) return this.router.parseUrl('/');
        return true;
      })
    );
  }
}
