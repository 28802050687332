import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { User } from '../../core/model/User';

@Pipe({
  name: 'username'
})
export class UsernamePipe implements PipeTransform {
  transform(value?: User | null): any {
    if (!value) {
      return '';
    }
    const { firstName, lastName } = value as User;
    return [firstName, lastName].join(' ');
  }
}

@NgModule({
  declarations: [UsernamePipe],
  exports: [UsernamePipe]
})
export class UsernamePipeModule {}
