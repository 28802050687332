import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { VersionService } from '@app/core/services/version.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  updateAvailable$: Observable<boolean>;

  constructor(private versionService: VersionService) {
    this.updateAvailable$ = this.versionService.updatesAvailable$.pipe(map((val) => !!val));
  }

  public onUpdateApp() {
    this.versionService.updateApp().subscribe();
  }
}
