import { Component, Input, NgModule } from '@angular/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { CommonModule } from '@angular/common';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { Router } from '@angular/router';
import { WorkspaceService } from '@app/core/services/api/workspace.service';
import { UserService } from '@app/core/services/api/user.service';
import { Observable } from 'rxjs';

import { InitialsPipeModule } from '../../pipes/initials.pipe';

@Component({
  selector: 'app-profile-dropdown',
  template: `
    <div
      class="profile-dropdown clickable"
      [class.profile-dropdown--active]="menuVisible"
      nz-dropdown
      [nzDropdownMenu]="menu"
      [nzTrigger]="'click'"
      [(nzVisible)]="menuVisible"
    >
      <nz-avatar
        [nzSize]="'small'"
        [nzSrc]="imgSrc | async"
        [nzText]="name | initials"
      ></nz-avatar>
      <span *ngIf="name">{{ name }}</span>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul
          class="profile-dropdown__menu"
          nz-menu
          nzSelectable
        >
          <li
            *ngFor="let item of menuItems"
            class="profile-dropdown__menu-item"
            [ngClass]="item.className"
            nz-menu-item
            (click)="item?.action?.()"
          >
            <i
              nz-icon
              [nzType]="item.icon"
            ></i>
            <span>{{ item.title }}</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  `,
  styleUrls: ['profile-dropdown.component.less']
})
export class ProfileDropdownComponent {
  @Input() public name?: string;
  @Input() public imgSrc?: Observable<string>;

  public menuItems = [
    {
      title: 'Account information',
      icon: 'icons:user',
      action: () => {
        this.workspaceService.currentWorkspace$.subscribe(({ slug }) =>
          this.router.navigate([slug, 'settings', 'account'])
        );
      },
      className: ''
    },
    {
      title: 'Log out',
      icon: 'icons:logout',
      action: () => this.logout(),
      className: 'logout'
    }
  ];
  public menuVisible = false;

  constructor(
    private userService: UserService, //
    private router: Router,
    private workspaceService: WorkspaceService
  ) {}

  private logout() {
    this.userService.logout().subscribe(() => {
      this.router.navigate(['home']);
    });
  }
}

@NgModule({
  declarations: [ProfileDropdownComponent],
  imports: [NzAvatarModule, InitialsPipeModule, CommonModule, NzDropDownModule, NzIconModule],
  exports: [ProfileDropdownComponent]
})
export class ProfileDropdownModule {}
