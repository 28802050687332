<div class="create">
  <div
    *ngIf="form"
    class="create__form"
    nz-form
    nzLayout="vertical"
    [formGroup]="form"
  >
    <app-text-input
      formControlName="workspace"
      [label]="'Create new workspace'"
      [placeholder]="'Name'"
    ></app-text-input>
    <div class="create__heading">Workspace thumbnail</div>
    <app-upload
      type="image"
      [ngModel]="inputFile"
      [ngModelOptions]="{ standalone: true }"
      [uploadMethod]="uploadMethod"
      [thumbnailPreview]="true"
      (ngModelChange)="onFileUploaded($event)"
    ></app-upload>
    <div
      class="create__buttons"
      nz-row
      nzJustify="center"
      [nzGutter]="16"
    >
      <div
        nz-col
        [nzSpan]="9"
      >
        <app-button
          [type]="'secondary'"
          [label]="'Cancel'"
          (btnClick)="cancel()"
        ></app-button>
      </div>
      <div
        nz-col
        [nzSpan]="9"
      >
        <app-button
          [type]="'primary'"
          [label]="'Submit'"
          [disabled]="form.invalid"
          (btnClick)="onSubmit()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
