import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { from, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  public readonly updatesAvailable$: Observable<any>;

  constructor(private swUpdate: SwUpdate) {
    this.updatesAvailable$ = swUpdate.versionUpdates.pipe(
      filter((evt) => evt.type === 'VERSION_READY'),
      map((evt: VersionReadyEvent) => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion
      }))
    );
  }

  public updateApp() {
    return from(this.swUpdate.activateUpdate()).pipe(tap(() => document.location.reload()));
  }
}
