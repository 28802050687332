export interface LayoutMenuItem {
  id: string;
  title: string;
  route: string;
  icon: string;
  disabled?: boolean;
  permission?: string;
  /**
   * Partial compare of active links.
   * If true, substrings are searched in URL. If false, URLs must be identical
   */
  partial?: boolean;
}

export const workspaceLayoutMenuItems: LayoutMenuItem[] = [
  {
    id: 'team',
    title: 'Team',
    route: '/team',
    icon: 'icons:team',
    permission: 'team.display'
  },
  {
    id: 'analytics',
    title: 'Analytics',
    route: '/analytics',
    icon: 'icons:analytics',
    partial: true,
    permission: 'analytics.display'
  },
  {
    id: 'payment',
    title: 'Payment',
    route: '/payment',
    icon: 'icons:payment',
    permission: 'payment.display'
  },
  {
    id: 'activations',
    title: 'AR activation',
    route: '/activations',
    icon: 'icons:activation',
    permission: 'activations.display'
  },
  {
    id: 'setting',
    title: 'Settings',
    route: '/settings',
    icon: 'icons:settings',
    permission: 'workspace.display'
  }
];
