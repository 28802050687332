import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

const ignoredPaths = ['/', '/home', '/auth/login', '/auth/registration', '/auth/new-password'];

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const { url } = this.router;
        if (!ignoredPaths.includes(url) && err.status === 403) {
          this.router.navigate(['']);
          return EMPTY;
        }
        return throwError(err);
      })
    );
  }
}
