import { Component, NgModule, OnInit } from '@angular/core';
import { MultiSelectInputModule } from '@app/shared/components/inputs/multi-select-input/multi-select-input.component';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextInputModule } from '@app/shared/components/inputs/text-input/text-input.component';
import { SelectOption } from '@app/core/model/SelectOption';
import { ButtonModule } from '@app/shared/components/button/button.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { FileData, UploadModule } from '@app/shared/components/upload/upload.component';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MediaService } from '@app/core/services/api/media.service';
import { NotificationsService } from '@app/core/services/api/notifications.service';
import { MessageService, MessageServiceModule } from '@app/core/services/message.service';

interface FaqItem {
  label: string;
  url: string;
}

@Component({
  selector: 'app-feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.less']
})
export class FeedbackComponent implements OnInit {
  public questionTopics: SelectOption[] = [
    { label: 'Activations', value: 'activations' },
    { label: 'Analytics', value: 'analytics' },
    { label: 'Workspace', value: 'workspace' }
  ];
  public questionSending = false;
  public feedbackForm: UntypedFormGroup;
  public faqItems$: Observable<FaqItem[] | null>;
  public fileUploaded = false;

  constructor(
    private modalRef: NzModalRef,
    private mediaService: MediaService,
    private notificationsService: NotificationsService,
    private messageService: MessageService
  ) {}

  public uploadMethod = (file: File) => this._uploadMethod(file);

  public ngOnInit(): void {
    this.initForm();
    this.faqItems$ = this.faqItemsObs();
  }

  public onSubmitQuestion() {
    if (this.feedbackForm.valid) {
      this.questionSending = true;
      this.notificationsService.sendFeedback(this.feedbackForm.getRawValue()).subscribe(
        () => {
          this.questionSending = false;
          this.modalRef.close();
        },
        (err) => {
          this.questionSending = false;
          this.messageService.error('Error sending feedback.');
          console.error(err);
        }
      );
    }
  }

  public onCancel() {
    this.modalRef.close();
  }

  public onFaqClick(item: FaqItem) {
    window.open(item.url);
  }

  public onFileUploaded(media: FileData | null) {
    this.fileUploaded = !!media;
    if (media) {
      this.feedbackForm.get('media').setValue(media.id);
    } else {
      this.feedbackForm.get('media').setValue(null);
    }
    this.feedbackForm.markAsDirty();
    if (!media) {
      return;
    }
  }

  private initForm() {
    this.feedbackForm = new UntypedFormGroup({
      topic: new UntypedFormControl('', [Validators.required]),
      message: new UntypedFormControl('', [Validators.required]),
      media: new UntypedFormControl('')
    });
  }

  private faqItemsObs() {
    return this.feedbackForm.get('topic').valueChanges.pipe(
      mergeMap((topic: string) => {
        if (!topic?.length) {
          return of(null);
        }

        const faqItems = [
          {
            label: 'Video upload troubleshooting',
            url: 'https://videobomb.co/'
          },
          {
            label: "My activation says it's “active” but the triggerimage won't work.",
            url: 'https://videobomb.co/'
          },
          {
            label: 'How can I reactivate an expired activation?',
            url: 'https://videobomb.co/'
          }
        ] as FaqItem[];
        return of(faqItems);
      })
    );
  }

  private _uploadMethod(file: File) {
    return this.mediaService.uploadFileWithProgress(file as File, 'image', 'feedback');
  }
}

@NgModule({
  imports: [
    MultiSelectInputModule, //
    ReactiveFormsModule,
    TextInputModule,
    ButtonModule,
    NzFormModule,
    UploadModule,
    MessageServiceModule,
    CommonModule,
    FormsModule
  ],
  exports: [],
  declarations: [FeedbackComponent]
})
export class FeedbackModule {}
