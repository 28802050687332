<div
  class="feedback"
  [formGroup]="feedbackForm"
  nz-form
  nzLayout="vertical"
>
  <div class="feedback__header">
    <div class="feedback__header__title">Got feedback?</div>
    <div class="feedback__header__content">
      We’re always looking to improve our product and would love to hear your feedback.
      <br />
      <br />
      Please write us a detailed message and a member of our team will review your submission.
    </div>
  </div>

  <app-multi-select-input
    [label]="'Topic'"
    [placeholder]="'Select a topic'"
    [options]="questionTopics"
    [suffixIcon]="'icons:chevron-down'"
    formControlName="topic"
  ></app-multi-select-input>
  <div
    *ngIf="faqItems$ | async as faqItems"
    class="feedback__faq"
  >
    <span class="feedback__header__title"> See FAQ related to </span>
    <ul class="faq">
      <li
        *ngFor="let item of faqItems"
        class="faq__item"
        (click)="onFaqClick(item)"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
  <app-text-input
    [label]="'Message'"
    [placeholder]="'Your question'"
    [textarea]="true"
    formControlName="message"
  ></app-text-input>

  <div class="feedback__upload">
    <div class="feedback__upload__header">
      <div class="label">Optional screenshot of interface</div>
      <div class="notes">You may attach an image to better explain your feedback.</div>
    </div>
    <app-upload
      type="image"
      [ngModel]="null"
      [ngModelOptions]="{ standalone: true }"
      [uploadMethod]="uploadMethod"
      (ngModelChange)="onFileUploaded($event)"
    ></app-upload>
  </div>

  <div class="feedback__actions">
    <app-button
      label="Cancel"
      [compact]="true"
      [type]="'secondary'"
      (btnClick)="onCancel()"
    ></app-button>
    <app-button
      label="Submit"
      [compact]="true"
      [loading]="questionSending"
      [disabled]="feedbackForm.invalid"
      (btnClick)="onSubmitQuestion()"
    ></app-button>
  </div>
</div>
