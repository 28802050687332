import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PermissionsObject, User } from '@app/core/model/User';
import { UserService } from '@app/core/services/api/user.service';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { Observable } from 'rxjs';
import { Workspace } from '@app/core/model/Workspace';
import { FeedbackComponent } from '@app/shared/components/modals/feedback/feedback.component';
import { map, pluck } from 'rxjs/operators';
import {
  LayoutMenuItem,
  workspaceLayoutMenuItems
} from '@app/shared/components/workspace-layout/workspace-layout.helper';
import { mediaPath } from '@app/helpers/media.helper';
import { PermissionsDirectiveModule } from '@app/shared/directives/permissions.directive';
import { LabelDropdownMenuModule } from '@app/shared/components/dropdown-menu/label-dropdown.component';
import { NzModalService } from 'ng-zorro-antd/modal';

import { UsernamePipeModule } from '../../pipes/username.pipe';
import { ButtonModule } from '../button/button.component';
import { DropDownMenuModule, MenuItem } from '../dropdown-menu/dropdown-menu.component';
import { LayoutModule } from '../layout/layout.component';
import { ProfileDropdownModule } from '../profile-dropdown/profile-dropdown.component';
import { WorkspaceSelectModule } from '../workspace-select/workspace-select.component';

@Component({
  selector: 'app-workspace-layout',
  templateUrl: 'workspace-layout.component.html',
  styleUrls: ['./workspace-layout.component.less']
})
export class WorkspaceLayoutComponent {
  public userAvatar$?: Observable<string>;
  public workspace?: Workspace;
  public user$?: Observable<User>;
  public userPermissions$?: Observable<PermissionsObject>;
  public menuItems: LayoutMenuItem[];

  helpMenuItems: MenuItem[] = [
    {
      label: 'Help center',
      icon: 'icons:question',
      action: () => {
        this.router.navigate(['./help'], { relativeTo: this.activatedRoute });
      }
    },
    {
      label: 'Replay tutorial',
      icon: 'icons:replay',
      action: () => {}
    }
  ];

  constructor(
    private router: Router, //
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private modalService: NzModalService
  ) {
    this.userPermissions$ = this.userService.user$.pipe(pluck('permissions'), pluck('frontend'));
    this.activatedRoute.data.subscribe((data) => {
      if (data?.workspace) {
        this.workspace = data.workspace;
        this.user$ = this.userService.user$;
        this.userAvatar$ = this.userService.avatar$.pipe(map((id) => mediaPath(id)));
      }
    });

    this.initMenu();
  }

  public isActive(route: string, partial?: boolean) {
    const compareRoute = `/${this.workspace?.slug}${route}`;

    if (partial) {
      return this.router.routerState.snapshot.url.includes(compareRoute);
    } else {
      return this.router.routerState.snapshot.url === compareRoute;
    }
  }

  public onFeedback() {
    this.modalService.create<FeedbackComponent>({
      nzContent: FeedbackComponent,
      nzTitle: null,
      nzFooter: null,
      nzClosable: false
    });
  }

  private initMenu() {
    this.menuItems = workspaceLayoutMenuItems;
  }
}

@NgModule({
  declarations: [WorkspaceLayoutComponent],
  exports: [WorkspaceLayoutComponent],
  imports: [
    FormsModule,
    WorkspaceSelectModule,
    ProfileDropdownModule,
    CommonModule,
    LayoutModule,
    RouterModule,
    NzIconModule,
    ButtonModule,
    NzMenuModule,
    UsernamePipeModule,
    DropDownMenuModule,
    PermissionsDirectiveModule,
    LabelDropdownMenuModule
  ]
})
export class WorkspaceLayoutModule {}
