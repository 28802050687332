import { Component, EventEmitter, NgModule, OnInit, Output } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { WorkspaceService } from '@app/core/services/api/workspace.service';
import { Observable } from 'rxjs';
import { Workspace } from '@app/core/model/Workspace';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { mediaPath } from '@app/helpers/media.helper';
import { PermissionsDirectiveModule } from '@app/shared/directives/permissions.directive';
import { UserService } from '@app/core/services/api/user.service';

import {
  CreateWorkspaceModalComponent,
  CreateWorkspaceModalModule
} from '../create-workspace-modal/create-workspace-modal.component';
import { ButtonModule } from '../button/button.component';

export interface WorkspaceItem {
  _id: string;
  name: string;
}

@Component({
  selector: 'app-workspace-select',
  template: `
    <div
      *ngIf="selected && workspaces$"
      class="workspace-dropdown clickable"
      nz-dropdown
      [class.workspace-dropdown--active]="menuVisible"
      [nzDropdownMenu]="menu"
      [nzDisabled]="(switchPermission | async) === false"
      [nzTrigger]="'click'"
      [(nzVisible)]="menuVisible"
    >
      <button
        nz-button
        class="workspace-dropdown__button"
        [ngClass]="{ button__clicked: menuVisible }"
      >
        <img
          class="workspace-dropdown__thumbnail"
          [src]="thumbnail"
          alt=""
        />
        <div class="workspace-dropdown__text-holder">
          <div class="title">
            {{ selected.name }}
          </div>
          <div class="description">Selected project</div>
        </div>
        <i
          class="right-icon"
          nz-icon
          [nzType]="'icons:workspaces'"
        ></i>
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul
          class="workspace-dropdown__menu"
          nz-menu
          nzSelectable
        >
          <li
            *ngFor="let workspace of workspaces$ | async"
            class="workspace-dropdown__workspace-item"
            nz-menu-item
            (click)="onWorkspaceSelected(workspace)"
          >
            <img
              class="workspace-dropdown__thumbnail"
              [src]="
                workspace.meta.thumbnail?.id
                  ? baseUrl + '/media/resource/' + workspace.meta.thumbnail?.id
                  : '/assets/images/ar-item-placeholder.png'
              "
              alt=""
            />
            <div class="workspace-dropdown__text-holder">
              <span class="title">{{ workspace.name }}</span>
            </div>
            <i
              *ngIf="workspace._id === selected._id"
              class="workspace-dropdown__check-icon"
              nz-icon
              [nzType]="'icons:check'"
            ></i>
          </li>
          <li
            *appPermissions="'workspace.create'"
            class="workspace-dropdown__new-item"
            nz-menu-item
            (click)="newWorkspace.emit()"
          >
            <i
              nz-icon
              class="workspace-dropdown__icon"
              [nzType]="'icons:plus-in-circle'"
            ></i>
            <div
              class="workspace-dropdown__text-holder"
              (click)="createNewWorkspace()"
            >
              <span class="new">Create workspace</span>
            </div>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  `,
  styles: [],
  styleUrls: ['workspace-select.component.less'],
  providers: []
})
export class WorkspaceSelectComponent implements OnInit {
  @Output() newWorkspace = new EventEmitter();
  public workspaces$?: Observable<Workspace[]>;
  public thumbnail = '/assets/images/ar-item-placeholder.png';
  public baseUrl = environment.baseUrl;
  public switchPermission: Observable<boolean>;

  selected?: Workspace;
  menuVisible = false;

  constructor(
    private modalService: NzModalService,
    private workspaceService: WorkspaceService,
    private router: Router,
    private userService: UserService
  ) {}

  public ngOnInit() {
    this.workspaces$ = this.workspaceService.workspaces$;
    this.workspaceService.currentWorkspace$.subscribe((currentWorkspace) => {
      if (!currentWorkspace) return;
      this.selected = currentWorkspace;
      this.setThumbnail(this.selected);
    });
    this.switchPermission = this.userService.categoryFeaturePermission$('workspace', 'display');
  }

  public onWorkspaceSelected(workspace: Workspace) {
    if (this.selected === workspace || !workspace) return;
    this.selected = workspace;
    this.setThumbnail(this.selected);
    this.router.navigate([workspace.slug], { replaceUrl: true });
  }

  public createNewWorkspace() {
    this.modalService.create({
      nzFooter: null,
      nzCloseIcon: 'icons:cross',
      nzContent: CreateWorkspaceModalComponent
    });
  }

  private setThumbnail(selected: Workspace): void {
    this.thumbnail = '/assets/images/ar-item-placeholder.png';
    if (selected?.meta.thumbnail?.id) {
      this.thumbnail = mediaPath(selected?.meta.thumbnail?.id);
    }
  }
}

@NgModule({
  declarations: [WorkspaceSelectComponent],
  imports: [
    ButtonModule,
    NzIconModule,
    NzButtonModule,
    NzDropDownModule,
    CommonModule,
    NzModalModule,
    CreateWorkspaceModalModule,
    PermissionsDirectiveModule
  ],
  exports: [WorkspaceSelectComponent]
})
export class WorkspaceSelectModule {}
