import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkspaceResolverService } from '@app/core/services/resolvers/workspace-resolver.service';
import {
  WorkspaceLayoutComponent,
  WorkspaceLayoutModule
} from '@app/shared/components/workspace-layout/workspace-layout.component';

import { ActivationsRoute } from './features/activations/model/ActivationsRouting';
import { FirstRunSetupGuard } from './shared/guards/first-run-setup.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./features/not-found/not-found.module').then((m) => m.NotFoundModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/authorization/authorization.module').then((m) => m.AuthorizationModule)
  },
  {
    path: 'first-run-setup',
    loadChildren: () => import('./features/first-run-setup/first-run-setup.module').then((m) => m.FirstRunSetupModule),
    canActivate: [FirstRunSetupGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: ':workspace',
    resolve: {
      workspace: WorkspaceResolverService
    },
    runGuardsAndResolvers: 'always',
    component: WorkspaceLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'activations'
      },
      {
        path: 'payment',
        loadChildren: () => import('./features/payment/payment.module').then((m) => m.PaymentModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./features/settings/settings.module').then((m) => m.SettingsModule)
      },
      {
        path: ActivationsRoute.root,
        loadChildren: () => import('./features/activations/activations.module').then((m) => m.ActivationsModule)
      },
      {
        path: 'team',
        loadChildren: () => import('./features/team/team.module').then((m) => m.TeamModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('./features/analytics/analytics.module').then((m) => m.AnalyticsModule)
      },
      {
        path: 'help',
        loadChildren: () => import('./features/help/help.module').then((m) => m.HelpModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }), WorkspaceLayoutModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
