import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { Workspace } from '../../model/Workspace';
import { WorkspaceService } from '../api/workspace.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceResolverService {
  constructor(private workspaceService: WorkspaceService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Workspace> | Promise<Workspace> {
    const workspaceSlug = route?.params?.workspace;
    const currentWorkspace = this.workspaceService.currentWorkspaceValue;
    const { slug } = currentWorkspace ?? {};

    if (!currentWorkspace || slug !== workspaceSlug) {
      return this.workspaceService.getWorkspace(workspaceSlug).pipe(
        mergeMap((ws) => {
          return this.workspaceService.switchWorkspace(ws._id).pipe(
            map(() => {
              return ws;
            }),
            catchError((e) => {
              console.error('Error loading workspace', e);
              return of(null);
            })
          );
        }),
        tap((ws) => {
          this.workspaceService.setCurrentWorkspace(ws);
        })
      );
    }
    return of(currentWorkspace);
  }
}
