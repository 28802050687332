import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MediaService } from '@app/core/services/api/media.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { WorkspaceService } from '@app/core/services/api/workspace.service';
import { MessageService, MessageServiceModule } from '@app/core/services/message.service';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { TextInputModule } from '../inputs/text-input/text-input.component';
import { FileData, UploadModule } from '../upload/upload.component';
import { ButtonModule } from '../button/button.component';

@Component({
  selector: 'app-create-workspace-modal',
  templateUrl: 'create-workspace-modal.component.html',
  styleUrls: ['create-workspace-modal.component.less']
})
export class CreateWorkspaceModalComponent {
  public inputFile?: FileData & { id: string };
  public media?: FileData | null;
  public fileUploaded = false;
  form = new UntypedFormGroup({
    workspace: new UntypedFormControl('', Validators.required)
  });
  constructor(
    private mediaService: MediaService,
    private modal: NzModalRef,
    private workspaceService: WorkspaceService,
    private messageService: MessageService,
    private modalService: NzModalService
  ) {}

  public uploadMethod = (file: File) => this._uploadMethod(file);

  public onFileUploaded(media: FileData | null) {
    this.fileUploaded = !!media;
    this.media = media;
    this.form.setControl('thumbnail', new UntypedFormControl(media));
    if (!media) {
      return;
    }
  }

  public onSubmit() {
    if (this.form.valid) {
      const thumbnail = this.form.get('thumbnail')?.value;
      const workspaceBody: any = this.workspaceService.buildWorkspaceBody(this.form.get('workspace')?.value, thumbnail);
      this.workspaceService
        .createWorkspace(workspaceBody)
        .pipe(
          mergeMap((data) => {
            if (thumbnail) {
              return this.mediaService.updateMedia(thumbnail.id, { ref: data._id });
            }
            return of(null);
          })
        )
        .subscribe(
          () => {
            this.messageService.success('Workspace created !');
            this.modal.triggerOk();
          },
          (err) => {
            this.messageService.error('Error: Unable to create workspace');
            console.error(err);
          }
        );
    }
  }

  public cancel() {
    if (!this.form.dirty) {
      this.modal.triggerCancel();
      return;
    }
    this.modalService.confirm({
      nzTitle: 'Are you sure ?',
      nzContent: 'Your data will be lost, are you sure you want to close this ?',
      nzOkText: 'Yes',
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.modal.triggerCancel();
      }
    });
  }

  private _uploadMethod(file: File) {
    return this.mediaService.uploadFileWithProgress(file as File, 'image', 'workspace');
  }
}

@NgModule({
  declarations: [CreateWorkspaceModalComponent],
  exports: [CreateWorkspaceModalComponent],
  imports: [
    CommonModule,
    TextInputModule,
    NzFormModule,
    ReactiveFormsModule,
    UploadModule,
    ButtonModule,
    MessageServiceModule,
    FormsModule
  ]
})
export class CreateWorkspaceModalModule {}
